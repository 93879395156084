
<div class="termsWrapper">
    <h4>How it works ?</h4>
    <div class="termscontent-wrap">
      <div class="contentswrap rafer_data_wrap">
        <!-- <ul>
          <li>
            <h5>Invite your friend on Gyan Fresh by sharing your referral code. However, the Referrer and the referee
              cannot have the same address</h5>
          </li>
          <li>
            <h5>Before sharing the code, you need to recharge your Gyan Fresh wallet with min ₹1000
            </h5>
  
          </li>
          <li>
            <h5>You are eligible to earn ₹75 only when your friend recharge with min ₹1000 in their Gyan Fresh wallet
              after the Sign Up, using the code shared by you
            </h5>
          </li>
          <li>
            <h5>Your friend earns ₹75 once recharged successfully</h5>
          </li>
          <li>
            <h5>You can refer maximum 5 new users
            </h5>
          </li>
          <li>
            <h5>Gyan Fresh at anytime reserves the right to modify or amend Terms and Conditions
            </h5>
          </li>
        </ul> -->
        <ul>
          <li>
            <h5> Refer a Friend: </h5>
          </li>
          <li>
            <h5> Invite your friend on Gyan Fresh by sharing your referral code. However, the Referrer and the Referee cannot have the same address </h5>
          </li>
          <li>
            <h5> Before sharing the code, you need to recharge your Gyan Fresh wallet with min ₹1000</h5>
          </li>
          <li>
            <h5> You are eligible to earn ₹50 only when your friend recharge with min ₹500 in their Gyan Fresh wallet after the Sign Up, using the code shared by you </h5>
          </li>
          <li>
            <h5>Your friend earns ₹50 once recharged successfully </h5>
          </li>
          <li>
            <h5>You can refer maximum 5 new users </h5>
          </li>
          <li>
            <h5>Gyan Fresh at any time reserves the right to modify or amend Terms and Conditions  </h5>
          </li>
        </ul>
      </div>
    </div>
  </div>