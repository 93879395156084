import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-daily-streak-terms',
  templateUrl: './daily-streak-terms.component.html',
  styleUrls: ['./daily-streak-terms.component.scss']
})
export class DailyStreakTermsComponent implements OnInit, AfterViewInit, OnDestroy {
  interval;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // if (this.route.snapshot.queryParams.od) {
    //   this.interval = setInterval(() => {
    //     let odEl = document.getElementById('onedirect-messenger-main');
    //     if (odEl && odEl.style) {
    //       odEl.style.display = 'none';
    //       // clearInterval(this.interval);
    //     }
    //   }, 500);
    // }
  }

  ngOnDestroy() {
    // clearInterval(this.interval);
  }

}
