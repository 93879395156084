<div class="termsWrapper">
  <h4>About Us</h4>
  <div class="termscontent-wrap">
    <h4 style="text-align: left;">Introduction</h4>
          <p> 
            In the years following our inception, we scaled up at a swift pace under the inspiring leadership 
            of our founder, Mr C.P. Agarwal and his visionary successors, Mr Jai Agarwal and Mr Anuj 
            Agarwal.</p>
          <p>          
            Today, we offer a diverse portfolio of 17 delightfully fresh, pure and delicious dairy products, 
            prepared using the milk supplied directly to us by 100,000+ dairy farmers. With their exceptional 
            support and that of our 600+ strong team, we seek to gain the trust of countless Indian families 
            and realise our vision to be the most loved dairy brand in India.
          </p>
        </div>
</div>
