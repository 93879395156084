<div class="termsWrapper spinwheelwrap">
  <!-- <h4 style="margin-bottom: 10px;">Try your luck to get 1 year of free milk</h4> -->
  <ng-container  *ngIf="spinLandObj && spinLandObj._id && !isSpinned">
    <div class="termscontent_wrap" >

      <ngx-wheel class="wheelcanvas"
      #wheel
        [width]="canWidth"
        [height]="canHeight"
        spinDuration="10"
        [disableSpinOnClick]="true"
        [items]="items"
        [innerRadius]="5"
        [spinAmount]="8"
        [textOrientation]="'horizontal'"
        [textAlignment]="'inner'"
        pointerStrokeColor="red"
        pointerFillColor="purple"
        [idToLandOn]="spinLandObj._id"
        (onSpinStart)="before()"
        (onSpinComplete)="after()"
        >
     </ngx-wheel>
      <img class="wheellarrow" src="assets/img/wheel_close_arrow_.png" alt="">
      <img class="wheellogo" src="assets/img/wheel_logo.png" alt="">
      </div>
      <!-- <div class="" *ngIf="isSpinned">
        <h4>Your reward is: </h4>
      </div> -->
      <div class="reset-button" style="margin-top: 15px;">
        <!-- <button (click)="reset()"  *ngIf="showReset">Reset</button> -->
        <button (click)="spin()"  *ngIf="showSpin">Spin</button>
      </div>
  </ng-container>
  <div class="how-to-play">
    <h4 data-toggle="modal" data-target="#modal-fullscreen">How to play?</h4>
  </div>
</div>




<!-- Modal Fullscreen -->
<div class="modal fade modal-fullscreen" id="modal-fullscreen" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" style="overflow: auto;">
      <!-- <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h4 class="modal-title" id="myModalLabel">Modal title</h4>
      </div>
      <div class="modal-body">
        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->

      <app-spin-wheel-terms></app-spin-wheel-terms>
    </div>
  </div>
</div>


<div class="modal fade" id="myModal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog custommodaldata">
    <div class="modal-content ">
      <!-- <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Modal Header</h4>
      </div> -->
      <div class="modal-body">
        <div class="sccuesswrap" *ngIf="!isClaimed">
          <div class="shadows"></div>
          <div class="contentwarp">
            <!-- <span class="close" data-dismiss="modal">&times;</span> -->
            <div class="wonlogo">
              <img src="assets/img/success_icon.png" alt="">
            </div>
            <h2> WHOOPEE!</h2>
            <p>You Won <br> {{spinLandObj?.title || '-'}} !</p>
            <button (click)="submit()">Claim</button>
          </div>
        </div>
        <div class="content_2" *ngIf="isClaimed">
          <p> Congratulations ! your reward processed successfully, place order to get reward</p>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div> -->
    </div>
  </div>
</div>
