import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {

  constructor(public afAuth: AngularFireAuth, private router: Router) { }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        // window.alert('You have been successfully registered!');
        // console.log(result.user)
      }).catch((error) => {
        // window.alert(error.message)
      })
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        //  this.router.navigate(['<!-- enter your route name here -->']);
        // console.log('SUCCESSFULLY AUHTENTICATED FIREBASE', result);
      }).catch((error) => {
        // window.alert(error.message);
        console.log('fiebase auth error', error.message);
      })
  }
}
