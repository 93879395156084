import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { app_strings } from 'src/app/shared/_constant/app_strings';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit, AfterViewInit, OnDestroy {
  placeholderImg = app_strings.PLACEHOLDER_IMAGE;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  };
  slidesStore = [{
    src: 'assets/img/testimonial/lata-Rastogi.png',
    alt: app_strings.PLACEHOLDER_IMAGE,
    title: 'Lata Rastogi',
    thumbnail: 'assets/img/testimonial/lata-Rastogi-compressed.jpg',
    id: '1'
  },
  {
    src: 'assets/img/testimonial/Shruti-Dwivedi.png',
    alt: app_strings.PLACEHOLDER_IMAGE,
    title: 'Shruti Dwivedi',
    thumbnail: 'assets/img/testimonial/Shruti-Dwivedi-compressed.jpg',
    id: '2'
  }
];
videoStore = [
  // { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/AK+Mathaur.mp4', name: 'A K Mathur', address: 'Lucknow' },
  // { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/Indraneel.mp4', name: 'Indraneel', address: 'Lucknow' },
  // { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/Lata+Rastogi.mp4', name: 'Lata Rastogi', address: 'Lucknow' },
  // { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/Mrs.+%26+Mr.+Tushar+Sharma.mp4', name: 'Mrs. & Mr. Tushar Sharma', address: 'Lucknow' },
  // { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/Prachi.mp4', name: 'Prachi', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/GF+Performance+Video+1.mov', name: 'Gyan Fresh Orders', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/GF+Performance+Video+2.mov', name: 'Gyan Fresh Orders', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/GF+Performance+Video+3.mov', name: 'Gyan Fresh Orders', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/GF+Performance+Video+4.mov', name: 'Gyan Fresh Orders', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/GF+Performance+Video+5.mov', name: 'Gyan Fresh Orders', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/GF+Performance+Video+6+-+End+Plate.mov', name: 'Gyan Fresh Orders', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/Prasoon+Kumar+Mahavani.mp4', name: 'Prasoon Kumar Mahavani', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/Seema+Srivastava.mp4', name: 'Seema Srivastava', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/Shreya+Bakshi.mp4', name: 'Shreya Bakshi', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/Shruti+%26+Siddharth+Diwedi.mp4', name: 'Shruti & Siddharth Diwedi', address: 'Lucknow' },
  { url: 'https://gyan-hd.s3.ap-south-1.amazonaws.com/Vijay+Verma.mp4', name: 'Vijay Verma', address: 'Lucknow' }
];

interval;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  onImgError(event){
    event.target.src = this.placeholderImg;
   }

   ngAfterViewInit() {
    // if (this.route.snapshot.queryParams.od) {
    //   this.interval = setInterval(() => {
    //     let odEl = document.getElementById('onedirect-messenger-main');
    //     if (odEl && odEl.style) {
    //       odEl.style.display = 'none';
    //       // clearInterval(this.interval);
    //     }
    //   }, 500);
    // }
   }

   ngOnDestroy() {
     // clearInterval(this.interval);
   }


}
