
<div *ngIf="data.type === 'noLocation'" class="thnksmodal">
  <h4>Thanks for your interest</h4>
  <h6>We will inform you when we launch in your area</h6>
  <button (click)="onGotItClick()">Got it</button>
</div>

<div *ngIf="data.type === 'noZipcode'" class="thnksmodal">
  <h4 class="errorDialog">! Zipcode not found</h4>
  <h6>Please enter zipcode in your primary address</h6>
  <button (click)="onGotItClick()">Got it</button>
</div>

<div *ngIf="data.type === 'checkAdd'" class="thnksmodal">
  <h4>Important !</h4>
  <h5>Please check and confirm your address as this couldn't be changed later
  </h5>
  <div class="row buttons_dd">
    <div class="col-md-6 col-xs-6">
      <button style="margin-left: 120px; color: #0a94d6; background-color: #0a94d624;" (click)="onGotItClick('cancel')">Cancel</button>
    </div>
    <div class="col-md-6 col-xs-6">
      <button style="margin-right: 120px;" (click)="onGotItClick()">Confirm</button>
    </div>
  </div>
</div>

<div *ngIf="data.type === 'ADDRESS'" class="thnksmodal">
  <!-- <form class="example-form">
    <mat-form-field class="example-full-width">
      <input type="text"
             placeholder="Pick one"
             aria-label="Number"
             matInput
             [formControl]="myControl"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of options" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form> -->
  <input id="adressGet" class="inputAuto"ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
  <button (click)="onGotItClick()">Continue</button>
</div>

<div *ngIf="data.type === 'wallet'" class="thnksmodal">
  <h4 style="text-align: center;">! Please Note</h4>
  <h5>The current Transaction is failed. In case of any amount deducted from your bank, it will be added to your wallet in next 2-3 days. If money is not added within 3 business days, please reach out to us.
  </h5>
  <div class="row">
    <div class="col-md-12 col-xs-12 text-center">
      <button (click)="onGotItClick('walletOK')">Continue</button>
    </div>
  </div>
</div>

<div *ngIf="data.type === 'productOffer'" class="thnksmodal">
  <h4 style="color: #0a94d6;">Offer</h4>
  <mat-radio-group
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  [(ngModel)]="offerSelected">
  <mat-radio-button class="example-radio-button" *ngFor="let item of offersArr" [value]="item">
    {{(item?.description | titlecase) || '-'}}
  </mat-radio-button>
</mat-radio-group>
<h5 style="color: #0a94d6;" *ngIf="!offersArr.length">No offer found</h5>
<h6 style="color: red; text-align: left;" *ngIf="submittedOffer && !offerSelected && offersArr.length">
Please select offer before apply
</h6>
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <button [disabled]="!offersArr.length" (click)="applyOffer()" [ngClass]="{'noCursor': !offersArr.length}">Apply</button>
    </div>
    <div class="col-md-6 col-xs-12">
      <button style="color: #0a94d6; background-color: #0a94d624;" (click)="onGotItClick('skip')">Skip</button>
    </div>
  </div>
</div>

<div *ngIf="data.type === 'howIT'" class="thnksmodal customIT how_it_desk">
  <app-how-it-works></app-how-it-works>
  <button (click)="onGotItClick('close')">Got it</button>
</div>

<div mat-dialog-content *ngIf="data.type === 'gyanStar'" class="thnksmodal customIT">
  <app-term-gyan-star></app-term-gyan-star>
  <button (click)="onGotItClick('close')">Got it</button>
</div>

<div *ngIf="data.type === 'coupon'" class="thnksmodal apply_offer_wrap">
  <div class="row">
    <div class="col-md-12">
      <h3>Apply Coupons</h3>
    </div>
    <div class="afterpromocode_div buttonIn">
        <input  class="form-control" placeholder="Enter coupon code here"
      id="enter" #couponVal>
      <div *ngIf="submittedCoupon && !couponSelected" style="color: red;">
      Enter coupon code
    </div>
      <button id="clear" (click)="couponSelected=couponVal.value; submittedCoupon=true; applyCoupon()">Apply</button>
      <!-- <span *ngIf="showCouponCodeFlag" (click)="showCouponCodeFlag = false" class="cncltext">cancel</span> -->
  </div>
  </div>
  <br>
  <div mat-dialog-content class="row offersList">
    <div class="col-md-12">
      <h4>Available Offers</h4>
    </div>
    <div class="col-md-12 couponList" *ngFor="let item of couponData">

        <mat-card>
          <div class="coupon_image">
            <span class="logo"><img src="assets/img/logoOnly.png"></span>
            <span class="code">{{item.couponCode}}</span>
          </div>
          <span class="applyBTN" (click)="couponVal.value=item.couponCode; couponSelected=item.couponCode; submittedCoupon=true; applyCoupon()">
            Apply
          </span>
          <h5 style="text-align: left;">{{(item.title.length > 30 && !item.expand) ? item.title.substr(0, 30) + '&hellip;' : item.title}}
            <span (click)="item.expand=!item.expand" class="viewDetails">{{item.expand ? 'Hide' : 'View'}} Details</span>
          </h5>
          <ul *ngIf="item.expand" class="view_list">
            <li *ngFor="let desc of item.couponDetails">{{desc}}</li>
          </ul>
        </mat-card>
    </div>
    <ng-container *ngIf="!couponData?.length">
      <div class="text-center">
        <h5 class="noCouponAvailable">No Coupons Available</h5>
      </div>
    </ng-container>
  </div>
  <!-- <button (click)="onGotItClick('close')">Got it</button> -->
</div>
