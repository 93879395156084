import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/serviceFile/api.service';
// import {FormControl} from '@angular/forms';
// import { debounceTime } from 'rxjs/operators';
// import { Subscription } from 'rxjs';

@Component({
  selector: 'app-global-dialog',
  templateUrl: './global-dialog.component.html',
  styleUrls: ['./global-dialog.component.scss']
})
export class GlobalDialogComponent implements OnInit {
  addressObj = {
    address: '',
    lat: 0,
    lng: 0
  };
  // myControl = new FormControl();
  // options: string[];
  apiKey = 'AIzaSyDAIJ08X0BznyCJXQiD5Dt3cRXWoj8WZd8';
  mapApiUrl = 'https://maps.googleapis.com/maps/api/geocode/json?';
  // inputSubs: Subscription;
  options = {
    componentRestrictions: {
      country: ['IN']
    }
  };
  offerSelected;
  offersArr = [];
  submittedOffer = false;
  couponData = [];
  couponSelected;
  submittedCoupon = false;

  constructor(public dialogRef: MatDialogRef<GlobalDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
              private apiService: ApiService) { }

  ngOnInit(): void {
    // this.getGeocodeOnManualInputAddress();
    if (this.data.type === 'productOffer') {
      this.getProductOffer();
    }
    this.couponData = this.data.data ? this.data.data : [];
  }

  onGotItClick(val?: string): void {
    if (val) {
      this.dialogRef.close(val);
      return;
    }
    this.dialogRef.close(this.addressObj);
    switch (this.data) {
      case 'noLocation' || 'noZipcode' || 'checkAdd':
        this.dialogRef.close('ok');
        break;
      case 'ADDRESS':
        this.dialogRef.close(this.addressObj);
        break;
      // case 'PRODUCT_OFFER':
      //   this.dialogRef.close(this.offerSelected);
      //   break;
      default:
        break;
    }
    // this.commonService.showSpinner();
  }

  // getGeocodeOnManualInputAddress() {
  //   // const userAdd = this.myControl.value;
  //   this.inputSubs = this.myControl.valueChanges.pipe(debounceTime(500)).subscribe(userAdd => {
  //     if (!userAdd) {
  //       // this.options = [];
  //       return;
  //     }
  //     const xhttp = new XMLHttpRequest();
  //     xhttp.onreadystatechange = () => {
  //     if (xhttp.readyState === 4 && xhttp.status === 200) {
  //       const latLng = JSON.parse(xhttp.responseText);
  //       this.options = latLng.results;
  //       console.log('adddress', latLng.results[0].geometry.location);
  //       // this.addAddressForm.controls.lat.setValue(latLng.results[0].geometry.location.lat);
  //       // this.addAddressForm.controls.long.setValue(latLng.results[0].geometry.location.lng);
  //     }
  //   };
  //     xhttp.open('GET', this.mapApiUrl + 'address=' + userAdd + '&components=country:IN&key=' + this.apiKey, true);
  //     xhttp.send();
  //   });
  // }

  // ngOnDestroy() {
  //   this.inputSubs.unsubscribe();
  // }

  public handleAddressChange(address: any) {
    // Do some stuff
    // console.log('selectedAddress', document.getElementById('adressGet')['value']);
    // this.addressObj.address = `${address.name}, ${address.formatted_address}`;
    this.addressObj.address = document.getElementById('adressGet')['value'];
    this.addressObj.lat = address.geometry.location.lat();
    this.addressObj.lng = address.geometry.location.lng();
    console.log('addressObj', this.addressObj);
  }

  getProductOffer() {
    console.log('check>>>', this.data);

    const obj = {
      item_id: this.data.data._id
    };
    this.apiService.postApi('checkProductOffer', obj).subscribe(res => {
      if (res && res.status === 200) {
        console.log('offerData', res.data);
        this.offersArr = res.data;
      }
    });
  }

  applyOffer() {
    this.submittedOffer = true;
    if (!this.offerSelected) {
      console.log('offer selected', this.offerSelected);
      return;
    }
    this.dialogRef.close(this.offerSelected);
  }

  applyCoupon() {
    this.submittedCoupon = true;
    if (!this.couponSelected) {
      return;
    }
    this.dialogRef.close(this.couponSelected);
  }

}
