import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CommonService } from '../serviceFile/common.service';
import { Router } from '@angular/router';


@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  constructor(private commonService: CommonService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const verifyOtpToken = localStorage.getItem('verifyOtpToken');
    const token = localStorage.getItem('userToken');
    const headerObj = {
      'Content-Type': 'application/json'
    };
    if (verifyOtpToken) {
      const otpHeader = req.clone({
        setHeaders: { token: verifyOtpToken }
      });
      return next.handle(otpHeader);

    } else if (req.headers.has('wheelToken')) {
      const tooken = req.headers.get('wheelToken');
      const clonedReq = req.clone({
        headers: new HttpHeaders({ token: tooken })
      });
      return next.handle(clonedReq);

    } else if (req.headers.get('noauth')) {
      const clonedreq = req.clone({
        headers: new HttpHeaders (headerObj)
    });
      return next.handle(clonedreq);

    } else if (token) {
      const authHeader = req.clone({
        setHeaders: { token }
      });
      return next.handle(authHeader).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.body.status === 403) {
            this.commonService.showError('Unauthorised');
            // logout user
            localStorage.clear();
            this.router.navigate(['/login']);

          }
        }, error => {
          this.commonService.hideSpinner();
          this.commonService.showError('An unexpected error occured, please try again');
        })
      );
    } else {
      const header = req.clone({
        setHeaders: { 'Content-Type': 'application/json' }
      });
      return next.handle(header);
    }
    // const authToken = 'kuldeep token';
    // const authRequired = req.clone({
    //   setHeaders: { Authorization: authToken, 'Content-Type': 'application/json' }
    // });
    // return next.handle(header);
  }
}
