import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StaticRoutingModule } from './static-routing.module';
import { TermComponent } from './term/term.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AboutComponent } from './about/about.component';
import { RefundComponent } from './refund/refund.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { TermGyanStarComponent } from './term-gyan-star/term-gyan-star.component';
import { HowItWorkDeskComponent } from './how-it-work-desk/how-it-work-desk.component';
import { PurityMeterComponent } from './purity-meter/purity-meter.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { CookingComponent } from './cooking/cooking.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {MatCardModule} from '@angular/material/card';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SpinWheelTermsComponent } from './spin-wheel-terms/spin-wheel-terms.component';
import { DailyStreakTermsComponent } from './daily-streak-terms/daily-streak-terms.component';
import { SpinWheelComponent } from './spin-wheel/spin-wheel.component';
import { NgxWheelModule } from 'ngx-wheel';


@NgModule({
  declarations: [TermComponent, PrivacyComponent, AboutComponent, RefundComponent, HowItWorksComponent, TermGyanStarComponent, HowItWorkDeskComponent, PurityMeterComponent, TestimonialComponent, CookingComponent, SpinWheelTermsComponent, DailyStreakTermsComponent, SpinWheelComponent],
  imports: [
    CommonModule,
    StaticRoutingModule,
    CarouselModule,
    MatCardModule,
    LazyLoadImageModule,
    NgxWheelModule
  ],
  exports: [HowItWorksComponent, TermGyanStarComponent, TermComponent,
    AboutComponent, RefundComponent, HowItWorkDeskComponent, PurityMeterComponent, TestimonialComponent,
    CookingComponent, PrivacyComponent, SpinWheelTermsComponent, DailyStreakTermsComponent, SpinWheelComponent]
})
export class StaticModule { }
