import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanLoad {

  constructor(private router: Router) {}
  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }
  canLoad(route: Route) {
    const storage: any = localStorage.getItem('userToken');
    if (storage) {
      this.router.navigate(['/page'], { replaceUrl: true });
      return false;
    }
    return true;
  }
}
