import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxWheelComponent } from 'ngx-wheel';
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime, take } from 'rxjs/operators';
import { StaticService } from '../static.service';

declare let $: any;

@Component({
  selector: 'app-spin-wheel',
  templateUrl: './spin-wheel.component.html',
  styleUrls: ['./spin-wheel.component.scss']
})
export class SpinWheelComponent implements OnInit, AfterViewInit, OnDestroy {
  interval;
  items = [];
  @ViewChild(NgxWheelComponent, { static: false }) wheel;
  showReset = false;
  showSpin = false;
  isSpinned = false;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  canWidth = '400';
  canHeight = '400';
  wheelDataItems = [];
  spinLandObj;
  userToken;
  isClaimed = false;
  timeout;
  rewardClaimFlag = false;

  constructor(private route: ActivatedRoute, private staticService: StaticService) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      console.log('checkParams', params);
      if (params && params.t) {
        this.userToken = params.t.toString();
      } else {
        this.userToken = localStorage.getItem('userToken');
      }
      this.getWheelData(this.userToken);
      // if (params && params.od) {
      //   this.interval = setInterval(() => {
      //     console.log('running interval');
      //     let odEl = document.getElementById('onedirect-messenger-main');
      //     if (odEl && odEl.style) {
      //       console.log('clearing interval');
      //       odEl.style.display = 'none';
      //       // clearInterval(this.interval);
      //     }
      //   }, 500);
      // }
    });
    if (window.innerWidth > 350 && window.innerWidth < 767) {
      this.canWidth = '320';
      this.canHeight = '320';
    }
    if (window.innerWidth < 350) {
      this.canWidth = '300';
      this.canHeight = '300';
    }
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$
      .pipe(debounceTime(1000)).subscribe(evt => {
        console.log('innerWidth: ', window.innerWidth)
        if (window.innerWidth > 350 && window.innerWidth < 767) {
          this.canWidth = '320';
          this.canHeight = '320';
          setTimeout(() => {
            this.canWidth = '320';
            this.canHeight = '320';
            this.reset();
          }, 500);
        } else if (window.innerWidth < 350) {
          this.canWidth = '300';
          this.canHeight = '300';
          setTimeout(() => {
            this.canWidth = '300';
            this.canHeight = '300';
            this.reset();
          }, 500);
        } else {
          this.canWidth = '400';
          this.canHeight = '400';
          setTimeout(() => {
            this.canWidth = '400';
            this.canHeight = '400';
            this.reset();
          }, 500);
        }

        // this.reset();
      });
  }

  ngAfterViewInit(): void {
    this.showSpin = true;
    $(window).load(() => {
      // alert("hi 2");
      // this.interval = setInterval(() => {
      //   console.log('running interval');
      //   const eleToRemove = document.getElementById('onedirect-messenger-main');
      //   if (eleToRemove && eleToRemove.style && eleToRemove.style.display) {
      //     eleToRemove.style.display = 'none';
      //     if (eleToRemove && eleToRemove.style && eleToRemove.style.display === 'none') {
      //       console.log('clearingInterval');
      //       clearInterval(this.interval);
      //     }
      //   }
      // }, 500);
    });
  }

  ngOnDestroy() {
    // clearInterval(this.interval);
    this.resizeSubscription$.unsubscribe();
  }

  before() {
    this.showReset = false;
    this.showSpin = false;
  }

  after() {
    // this.showReset = true;
    // this.isSpinned = true;
    // this.submit();
    $('#myModal').modal('show');
    this.isSpinned = true;
    this.claimReward();
    this.timeout = setTimeout(() => {
      this.submit();
    }, 5000);
  }

  reset() {
    // Reset allows you to redraw the wheel
    // Use it after any change to wheel configurations or to allow re-spinning
    this.showReset = false;
    this.showSpin = true;
    this.wheel.reset();
  }

  spin() {
    this.wheel.spin();
  }

  getWheelData(token: string) {
    this.staticService.getWheel('getWheel', token).pipe(take(1)).subscribe(res => {
      if (res && res.status === 200) {
        let counter = 1;
        if (window.innerWidth < 349) {
          this.items = res.data.wheelData.map(data => {
            //  counter++;
            return { text: data.title, fillStyle: data.color, id: data._id, textFontSize: 9 }
          })
        } else {
          this.items = res.data.wheelData.map(data => {
            //  counter++;
            return { text: data.title, fillStyle: data.color, id: data._id, textFontSize: 12 }
          })
        }
        console.log('newITems', this.items);
        this.wheelDataItems = res.data.wheelData;
        this.spinLandObj = res.data.arrowComesPosition;
        this.reset();
        setTimeout(() => {
          this.reset();
          this.reset();
        }, 1000);
      }
    });
  }

  submit() {
    try {
      clearTimeout(this.timeout);
    } catch (error) {
      console.log(error);
    }
    if (this.rewardClaimFlag) {
      this.isClaimed = true;
    }
  }

  claimReward() {
    const obj = {
      spinWheelId: this.spinLandObj._id
    };
    this.staticService.redeemPrize('redeemPrizeFromWheel', this.userToken, obj).pipe(take(1)).subscribe(res => {
      if (res && res.status === 200) {
        this.rewardClaimFlag = true;
      }
    });
  }

}
