import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MessagingService } from './serviceFile/messaging.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
// import * as firebase from 'firebase';

declare let clevertap: any;
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'gyanWeb';
  message;
  envStatus = environment.paytm.script;

  constructor(private messagingService: MessagingService, private deviceService: DeviceDetectorService,
              private router: Router) { }

  ngOnInit() {
    // this.messagingService.requestPermission()
    // this.messagingService.receiveMessage()
    // this.message = this.messagingService.currentMessage

  }

  ngAfterViewInit() {
    // firebase.analytics();
    clevertap.notifications.push({
      titleText:'Would you like to receive Push Notifications?',
      bodyText:'We promise to only send you relevant content and give you updates on your transactions',
      okButtonText:'Sign me up!',
      rejectButtonText:'No thanks',
      okButtonColor:'#f28046',
      askAgainTimeInSeconds:50000, //optional, if not specified, the default value is one week
  });
    this.getDeviceType();
    if (this.envStatus === 'paytmProd') {
      this.router.events.subscribe((y: NavigationEnd) => {
        if (y instanceof NavigationEnd){
          const id = new Date().getTime();
          fbq('track', 'PageView', {}, {eventID: `PageView_${id}`});
        }
      });
    }
  }

  // function for device detector
  getDeviceType() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    switch (deviceInfo.os) {
        case 'Android':
          localStorage.setItem('deviceType', 'Android Browser');
          break;

        case 'iOS':
          localStorage.setItem('deviceType', 'iOS browser');
          break;

        default:
          localStorage.setItem('deviceType', 'Desktop');
          break;
      }
  }
}
