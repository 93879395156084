import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@NgModule({
  imports: [MatDialogModule,
  MatSelectModule,
  MatAutocompleteModule],
  exports: [MatDialogModule,
  MatSelectModule,
  MatAutocompleteModule]
})

export class MaterialModule {}
