import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-spin-wheel-terms',
  templateUrl: './spin-wheel-terms.component.html',
  styleUrls: ['./spin-wheel-terms.component.scss']
})
export class SpinWheelTermsComponent implements OnInit, AfterViewInit, OnDestroy {
  showTerms = false;
  interval;
  addClass = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.route.snapshot.queryParams.od) {
      this.addClass = true;
      this.interval = setInterval(() => {
        let odEl = document.getElementById('onedirect-messenger-main');
        if (odEl && odEl.style) {
          odEl.style.display = 'none';
          // clearInterval(this.interval);
        }
      }, 500);
    }
  }

  ngOnDestroy() {
    // clearInterval(this.interval);
  }

}
