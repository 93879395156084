export let app_strings = {
  CT_APP_HOME_VIEW : 'Home Screen',
CT_LOGIN_SUCCESS : 'Login Success',
CT_SIGNUP_SUCCESS : 'Signup Success',
CT_SEND_OTP_SUCCESS : 'OTP Send',
CT_RESEND_SUCCESS : 'Resend OTP',
CT_ORDER_SUCCESS : 'Product Ordered',
CT_PRODUCT_ADDED_TO_CART : 'Product Added To Cart',
CT_WALLET_RECHARGE_SUCCESS : 'Wallet Recharged',
CT_WALLET_RECHARGE_PROCESS : 'Wallet Recharge In Process',
CT_WALLET_CHECKED : 'Wallet Amount Checked',
CT_CATEGORY_CHECKED: 'Category Viewed',
CT_PaymentType: {
  // applePay: "Apple Pay"
  stripe: 'Stripe Pay',
  hdfcPay: 'HDFC Pay',
  cashCollect: 'Cash collect'
  },
CT_PRODUCT_CHECKED: 'Product Viewed',
NOTIFICATION_RECEIVED: 'Notification Received',
CATEGORY_DETAILS: {
  'Category Name': ''
},
CT_CHARGED: 'Charged',
OFFER_CHECK: 'Offer Checked',
CUSTOME: {
  PLATFORM : 'platform',
 PHONE_NUMBER : 'phoneNumber',
 WALLET_AMOUNT : 'walletAmount',
 WALLET_STATUS : 'walletStatus',
 RECHARGE_AMOUNT : 'rechargeAmount',
 PAYMENT_TYPE : 'paymentType',
 ADDED_AMOUNT : 'addedAmount',
 PRODUCT_NAME : 'productName',
 PRODUCT_ID : 'productId',
 PRODUCT_PRICE : 'productPrice',
 PRODUCT_IMAGE : 'productImage',
 CATEGORY_NAME : 'categoryName',
 DISCOUNT : 'discount',
 TOTAL_AMOUNT : 'total_amount',
 ORDER_ID : 'orderId',
 REFERRAL_ID: 'referralId',
 BUY_ONCE: 'Product Buy Once',
 SUBSCRIBE: 'Product Subscribe',
 TOMORROW_ORDER: 'Tomorrow Orders',
 FUTURE_ORDER: 'Future Orders',
 DEACTIVATE_ACCOUNT: 'Deactivate Account',
 MY_ORDER: 'My Order',
 CITY_NAME: 'City Name',
 REFERRAL: 'Send Referral',
 GENERAL_OFFER: 'General Offers',
 MY_OFFER: 'My Offer',
 FIRST_TIME_RECHARGE: 'First Time Recharge',
 FIRST_TIME_BUY: 'First Time Buy',
 REPEAT_RECHARGE: 'Repeat Recharge',
 REPEAT_ORDER: 'Repeat Order'
},
PAYTM_STAGING: {
  MID: 'CPMilk85430326666448',
  merchantKey: 'yc8b1&b%c3ZMpHFW',
  website: 'PREPROD',
  channelId: 'WEB',
  industryTypeId: 'Retail'
},

// PAYTM_STAGING: {
//   MID: 'bqJzaF31625933919525',
//   merchantKey: 'yc8b1&b%c3ZMpHFW',
//   website: 'PREPROD',
//   channelId: 'WEB',
//   industryTypeId: 'Retail'
// },


PAYTM_PRODUCTION: {
  MID: 'CPMilk48439701929971',
  merchantKey: 'c&ndu4nAyKTWt#QG',
  website: 'WEBPROD',
  channelId: 'WEB',
  industryTypeId: 'Retail105'
},
PLACEHOLDER_IMAGE: 'assets/img/placeholder.png',
apiVersionDev: 'v6',
apiVersionProd: 'v5',
FIREBASE_ANALYTICS: {
  LOGIN_SUCCESS: 'login_success',
  SIGN_UP_SUCCESS: 'signup_success',
  OTP_SEND: 'otp_send',
  RESEND_OTP: 'resend_otp',
  WALLET_AMOUNT_CHECKED: 'wallet_amount_checked',
  WALLET_RECHARGE_IN_PROCESS: 'wallet_recharge_in_process',
  WALLET_RECHARGED: 'wallet_recharged',
  PRODUCT_ADDED_TO_CART: 'product_added_to_cart',
  PRODUCT_ORDERED: 'product_ordered',
  REFERRAL: 'send_referral',
  CATEGORY_VIEWED: 'category_viewed',
  PRODUCT_VIEWED: 'product_viewed',
  DELETE_ACCOUNT: 'delete_account',
  DEACTIVATE_ACCOUNT: 'deactivate_account',
  GENERAL_OFFERS: 'general_offers',
  MY_OFFER: 'my_offer',
  MY_ORDER: 'my_order',
  PRODUCT_SUBSCRIBED: 'product_subscribe',
  PRODUCT_BUY_ONCE: 'Product_buy_once',
  FIRST_TIME_RECHARGE: 'first_time_recharge',
  FIRST_TIME_BUY: 'first_time_buy',
  HOME_SCREEN_VIEWED: 'home_screen',
  OFFER_CHECKED: 'offer_checked',
  TOMORROW_ORDER: 'tomorrow_orders',
  FUTURE_ORDER: 'future_orders',
  DEFAULT_CITY_SELECTED: 'city_selected',
  REPEAT_RECHARGE: 'repeat_recharge',
  REPEAT_ORDER: 'repeat_order'
},
FACEBOOK_PIXEL: {
  SIGN_UP: 'SignUp',
  FIRST_WALLET_RECHARGE: 'FirstWalletRecharge',
  FIRST_ORDER: 'FirstOrder',
  SUBSCRIBE: 'Subscribe',
  REPEAT_ORDER: 'RepeatOrder',
  PRODUCT_CATEGORY: 'ProductCategory',
  REPEAT_RECHARGE: 'RepeatRecharge'
},
GOOGLE_ANALYTICS: {
  SIGN_UP: 'SignUp',
  FIRST_WALLET_RECHARGE: 'FirstWalletRecharge',
  FIRST_ORDER: 'FirstOrder',
  SUBSCRIBE: 'Subscribe',
  REPEAT_ORDER: 'RepeatOrder',
  PRODUCT_CATEGORY: 'ProductCategory',
  REPEAT_RECHARGE: 'RepeatRecharge'
}
};
