<ng-container *ngIf="!showTerms; else terms">
  <div class="spinWrapper" [ngClass]="{'spin-parent-condition': addClass}">
      <div class="row spinFirst">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="spincontent">
              <img src="assets/img/spinTC.jpg" alt="">
              <h5>This is a jackpot reward with chance to win 1 Year of Free Milk and much more</h5>
            </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="how_work">
            <h4>HOW IT WORKS</h4>
            <P>
              Spin the Wheel to get exciting cashbacks , products or lucky jackpot
            </P>
          </div>
        </div>
      </div>
      <div class="row">
      <div class="offer_details_wrapdv">
        <div class="offer_details" (click)="showTerms = !showTerms">
          <i class="fa fa-file-text fileicon" aria-hidden="true"></i>
          <span>Offer Details</span>
          <i class="fa fa-angle-right fileicon1" aria-hidden="true"></i>
        </div>
      </div>
    </div>
       <div class="row playnowbtn">
            <div class="offer_details">
              <!-- <h4><u (click)="showTerms = !showTerms">Offer Details</u></h4> -->
              <p class="pull-left yur_chance_win"> Your chance to win</p>
              <div class="input_fields pull-right">
                <a class="formbtns" href="javascript:void(0)" data-dismiss="modal">Play Now</a>
              </div>
            </div>
        </div>


  </div>
</ng-container>

<ng-template #terms>
  <div class="termsWrapper">
    <h4 class="heading">Spin Wheel Offer T&C</h4>
    <div class="termscontent-wrap">
      <h4 style="text-align: left">1. Year/Month Free milk</h4>
      <p>Offer valid only on purchase of any milk SKU.</p>
      <p>Valid from the day of spin.</p>

      <h4 style="text-align: left;">2. Cashback</h4>
      <p>Cashback is added in wallet.</p>
      <p>Cashback is not encashable and will be forfeited in case of refund.</p>

      <h4 style="text-align: left;">3. Product Free</h4>
      <p>Product will come with order tomorrow.</p>
      <p>valid only if an order is placed for next day.</p>

      <h4 style="text-align: left;">4. 1 Week Milk free</h4>
      <p>Free milk will be delivered every 7th day</p>
      <p>Valid only if customer has continuous subscription of milk</p>
    </div>
    <div>
      <div class="input_fields">
        <a class="formbtns" (click)="showTerms=!showTerms" style="margin-top: 25px;">Back</a>
      </div>
    </div>
  </div>
</ng-template>
