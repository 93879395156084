import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
// import { SwPush } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { GlobalDialogComponent } from '../shared/global-dialog/global-dialog.component';

@Injectable()
export class CommonService {

  constructor(private toastr: ToastrService, private spinner: NgxSpinnerService, public dialog: MatDialog) { }

  showSuccess(message: string, title?: string) {
    if (title) {
      this.toastr.success(message, title);
    } else {
      this.toastr.success(message);
    }
  }
  showWarning(message: string) {
    this.toastr.warning(message);
  }
  showError(message: string) {
    this.toastr.error(message);
  }
  showInfo(message: string) {
    this.toastr.info(message);
  }

  // function to show spinner
  showSpinner() {
    this.spinner.show();
  }

  // function to hide spinner
  hideSpinner() {
    this.spinner.hide();
  }

  // function to fire swal
  confirmPopup(texxt, iconn) {
    return Swal.fire({
      title: 'Are you sure?',
      text: texxt,
      icon: iconn,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    });
  }

  // function to handle notification click
  // handleNotificationClick() {
  //   this.swPush.notificationClicks.subscribe( arg =>
  //     {
  //       // console.log(
  //       //   'Action: ' + arg.action,
  //       //   'Notification data: ' + arg.notification.data,
  //       //   'Notification data.url: ' + arg.notification.data.url,
  //       //   'Notification data.body: ' + arg.notification.body,
  //       // );
  //       console.log('clickedNotification', arg);

  //    });
  // }

  openDialog(modalType: string, disableClosePopup: boolean, modalData?: any) {
    // console.log('tableData', selectedMerchant);
    const dialogRef = this.dialog.open(GlobalDialogComponent, {
      width: '500px',
      height: 'auto',
      disableClose: disableClosePopup,
      data: { type: modalType,
              data: modalData
      }
    });

    return dialogRef.afterClosed();
  }
}
