<div class="wrapper">
   <div class="quality_wrap">
    <div class="header_wrap">
      <img src="assets/img/logoOnly.png" alt="">
    </div>
    <!-- <a data-toggle="modal" data-target="#rating_tip">
      <span>Rating Tip</span>
    </a> -->
    <div>
     <div class="purity_meter_wrap">
        <div class="header_meter">
          <!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> -->
          <p>Milk Life Cycle</p>
        </div>
     <div class="tab_data_card">
      <ul class="nav nav-pills">
        <li class="" *ngFor="let item of dataList; let i = index"
        [ngClass]="{'active': selectedData._id === item._id}" (click)="selectedData=item; counterAnimate()">
          <a href="#milk_id1{{i}}" data-toggle="tab">{{item.category}}</a>
        </li>
        <!-- <li><a href="#milk_id2" data-toggle="tab">Milk Tond</a>
        </li>
        <li><a href="#milk_id3" data-toggle="tab">Milk 1</a>
        </li>
        <li><a href="#milk_id4" data-toggle="tab">Milk 2</a>
        </li> -->
      </ul>
     </div>
        <div class="tab-content clearfix">
          <div class="tab-pane " id="milk_id1{{i}}" *ngFor="let item of dataList; let i=index"
          [ngClass]="{'active': selectedData._id === item._id}">
            <div class="purity_banner_wrap">
              <div class="banner_milk"><img src="assets/img/Group1.png" alt=""></div>
              <div class="all_card_wrap purity_mtr">
                <div class="left_card">
                  <div class="meter-image-div">
                    <img class="meter-image" src="assets/img/gauge-bg.jpg">
                    <img id="gauge-niddle" class="gauge-niddle" src="assets/img/gauge-niddle.png">
                    <style type="text/css">
                      #gauge-niddle {
                        -webkit-animation: spin 2s 1 linear;
                        transform: rotate(165deg);
                      }
                       @-webkit-keyframes spin {
                        0% {
                          -webkit-transform: rotate(-20deg)
                        }

                        80% {
                          -webkit-transform: rotate(200deg)
                        }

                        100% {
                          -webkit-transform: rotate(165deg)
                        }
                      }
                    </style>
                  </div>
                </div>
                <div class="right_card">
                  <div class="score-outer">
                    <div class="text-center score">
                      <p class="sub-title">Today’s Score</p>
                      <span class="counter ng-binding count_value">{{item.todayScore}}</span>
                      <p class="sub-title">Out of {{item.totalScore}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small_box_wrap">
                <div class="count_points">
                  <p> Adulterant</p>
                  <span> NO</span>
                </div>

                <div class="count_points">
                  <p> Acidity <span style="font-size: 15px;">({{item.acidity?.unitType}})</span></p>
                  <span> {{item.acidity?.mid}}</span>
                </div>
                <div class="count_points">
                  <p> Grade</p>
                  <span> A</span>
                </div>
              </div>
            </div>
            <div class="data_details_wrap">
              <ul>
                <li *ngFor="let nutri of item.nutrition">
                  <ng-container *ngIf="nutri.unitType !== 'degree'; else degree">
                    <div class="all_card_wrap loadd">
                      <div class="left_card">
                        <div class="left-content">
                          <label for="">{{nutri.name | uppercase}} <span>({{nutri.unitType}})</span></label>
                          <p>{{nutri.mid}} </p>
                        </div>
                        <!-- <p class="standard_dv">STANDARD {{nutri.min}} - {{nutri.max}} %</p> -->
                      </div>
                      <div class="right_card rightLoad">
                        <p class="">{{nutri.description}}</p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #degree>
                    <div class="all_card_wrap loadd">
                      <div class="left_card">
                        <div class="left-content">
                          <label for="">STORAGE TEMP <span>(&deg;C)</span></label>
                        <p>{{nutri.mid}}</p>
                        </div>
                        <!-- <p class="standard_dv">STANDARD {{nutri.min}} - {{nutri.max}} %</p> -->
                      </div>
                      <div class="right_card rightLoad">
                        <p class="">{{nutri.description}}</p>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <!-- <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> SNF</label>
                      <p>{{item.snfMin}}<span> %</span> </p>
                      <p class="standard_dv">STANDARD {{item.snfMax}} %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> MBRT</label>
                      <p>{{item.MBRTMin}}<span> (Sec)</span> </p>
                      <p class="standard_dv">STANDARD {{item.MBRTMax}} (Sec)</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> ACIDITY</label>
                      <p>{{item.acidityMin}}<span> %</span> </p>
                      <p class="standard_dv">STANDARD {{item.acidityMax}} %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> STORAGE TEMP</label>
                      <p>{{item.storageTempMin}}<span> &deg;C</span> </p>
                      <p class="standard_dv">STANDARD {{item.storageTempMax}} &deg;C</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li> -->
              </ul>
            </div>
          </div>
          <!-- <div class="tab-pane" id="milk_id2">
            <div class="purity_banner_wrap">
              <div class="banner_milk"><img src="assets/img/Milk_Journey.png" alt=""></div>
              <div class="all_card_wrap purity_mtr">
                <div class="left_card">
                  <div class="meter-image-div">
                    <img class="meter-image" src="assets/img/gauge-bg.jpg">
                    <img id="gauge-niddle" class="gauge-niddle" src="assets/img/gauge-niddle.png">
                    <style type="text/css">
                      #gauge-niddle {
                        -webkit-animation: spin 2s 1 linear;
                        transform: rotate(165deg);
                      }

                      @-webkit-keyframes spin {
                        0% {
                          -webkit-transform: rotate(-20deg)
                        }

                        80% {
                          -webkit-transform: rotate(200deg)
                        }

                        100% {
                          -webkit-transform: rotate(165deg)
                        }
                      }
                    </style>
                  </div>
                </div>
                <div class="right_card">
                  <div class="score-outer">
                    <div class="text-center score">
                      <p class="sub-title">Today’s Score</p>
                      <span class="counter ng-binding count_value">650</span>
                      <p class="sub-title">Out of 700</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small_box_wrap">
                <div class="count_points">
                  <p> Adulterant</p>
                  <span> NO</span>
                </div>

                <div class="count_points">
                  <p> Acidity</p>
                  <span> 0.135</span>
                </div>
                <div class="count_points">
                  <p> Grade</p>
                  <span> A</span>
                </div>
              </div>
            </div>
            <div class="data_details_wrap">
              <ul>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>9.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>10.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="tab-pane" id="milk_id3">
            <div class="purity_banner_wrap">
              <div class="banner_milk"><img src="assets/img/Milk_Journey.png" alt=""></div>
              <div class="all_card_wrap purity_mtr">
                <div class="left_card">
                  <div class="meter-image-div">
                    <img class="meter-image" src="assets/img/gauge-bg.jpg">
                    <img id="gauge-niddle" class="gauge-niddle" src="assets/img/gauge-niddle.png">
                    <style type="text/css">
                      #gauge-niddle {
                        -webkit-animation: spin 2s 1 linear;
                        transform: rotate(165deg);
                      }

                      @-webkit-keyframes spin {
                        0% {
                          -webkit-transform: rotate(-20deg)
                        }

                        80% {
                          -webkit-transform: rotate(200deg)
                        }

                        100% {
                          -webkit-transform: rotate(165deg)
                        }
                      }
                    </style>
                  </div>
                </div>
                <div class="right_card">
                  <div class="score-outer">
                    <div class="text-center score">
                      <p class="sub-title">Today’s Score</p>
                      <span class="counter ng-binding count_value">600</span>
                      <p class="sub-title">Out of 700</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small_box_wrap">
                <div class="count_points">
                  <p> Adulterant</p>
                  <span> NO</span>
                </div>

                <div class="count_points">
                  <p> Acidity</p>
                  <span> 15</span>
                </div>
                <div class="count_points">
                  <p> Grade</p>
                  <span> A</span>
                </div>
              </div>
            </div>
            <div class="data_details_wrap">
              <ul>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>2.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>10.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>3.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="tab-pane" id="milk_id4">
            <div class="purity_banner_wrap">
              <div class="banner_milk"><img src="assets/img/Milk_Journey.png" alt=""></div>
              <div class="all_card_wrap purity_mtr">
                <div class="left_card">
                  <div class="meter-image-div">
                    <img class="meter-image" src="assets/img/gauge-bg.jpg">
                    <img id="gauge-niddle" class="gauge-niddle" src="assets/img/gauge-niddle.png">
                    <style type="text/css">
                      #gauge-niddle {
                        -webkit-animation: spin 2s 1 linear;
                        transform: rotate(165deg);
                      }

                      @-webkit-keyframes spin {
                        0% {
                          -webkit-transform: rotate(-20deg)
                        }

                        80% {
                          -webkit-transform: rotate(200deg)
                        }

                        100% {
                          -webkit-transform: rotate(165deg)
                        }
                      }
                    </style>
                  </div>
                </div>
                <div class="right_card">
                  <div class="score-outer">
                    <div class="text-center score">
                      <p class="sub-title">Today’s Score</p>
                      <span class="counter ng-binding count_value">660</span>
                      <p class="sub-title">Out of 700</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small_box_wrap">
                <div class="count_points">
                  <p> Adulterant</p>
                  <span> NO</span>
                </div>

                <div class="count_points">
                  <p> Acidity</p>
                  <span> 10</span>
                </div>
                <div class="count_points">
                  <p> Grade</p>
                  <span> B</span>
                </div>
              </div>
            </div>
            <div class="data_details_wrap">
              <ul>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>11.00<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>4.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="all_card_wrap">
                    <div class="left_card">
                      <label for=""> FAT</label>
                      <p>7.3<span> %</span> </p>
                      <p class="standard_dv">STANDARD 6 %</p>
                    </div>
                    <div class="right_card">
                      <p class="">Percent Fat Content - Whole buffalo milk fat varies between 5% - 6% depends on feed and
                        weather</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div> -->
        </div>

      </div>
    </div>
  </div>
  </div>
  <div class="modal fade modal_centers" id="rating_tip" tabindex="-1" role="dialog" aria-labelledby="rating_tip" aria-hidden="true">
    <div class="modal-dialog ratingmodal" role="document">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div> -->
        <div class="modal-body rechREf">
        <div class="rating_wrapper">
          <h2 class="rating_heading">Tip & Rate your delivery champion</h2>
          <div class="rating_head dflex dflex_bet">
            <p class="rating_heading">Add a Tip <br> <span>Gyan Fresh doesn't charge service fees on tip</span> </p> <button>ADD</button>
          </div>
          <div class="walltet_data">
            <label class="radio"><img src="assets/img/wallet_side.png" alt="">Wallet <input  name="is_company" type="radio"><span  class="checkround"></span></label>
            <label class="radio"><img class="starmark" src="assets/img/a.png" alt=""> Gyan Star <input  name="is_company" type="radio"><span  class="checkround"></span></label>
          <div class="ammout_wrap">
            <ul>
              <li>
                <input id="5hundered" name="selectamount" type="radio" value="500">
                <label for="5hundered"><i aria-hidden="true" class="fa fa-plus plusIcon"></i>₹ 5</label>
              </li>
              <li>
                <input id="5hundered" name="selectamount" type="radio" value="500">
                <label for="5hundered"><i aria-hidden="true" class="fa fa-plus plusIcon"></i>₹ 10</label>
              </li>
              <li>
                <input id="5hundered" name="selectamount" type="radio" value="500">
                <label for="5hundered"><i aria-hidden="true" class="fa fa-plus plusIcon"></i>₹ 20</label>
              </li>
              <li>
                <input id="5hundered" name="selectamount" type="radio" value="500">
                <label for="5hundered"><i aria-hidden="true" class="fa fa-plus plusIcon"></i>₹ 40</label>
              </li>
            </ul>
          </div>
          </div>
  <div class="rating_one">
    <h2 class="rating_heading"> Rate your champion</h2>
    <form class="rating">
        <label>
            <input type="radio" name="stars" value="1">
            <span class="icon">★</span>
        </label>
        <label>
            <input type="radio" name="stars" value="2">
            <span class="icon">★</span>
            <span class="icon">★</span>
        </label>
        <label>
            <input type="radio" name="stars" value="3">
            <span class="icon">★</span>
            <span class="icon">★</span>
            <span class="icon">★</span>
        </label>
        <label>
            <input type="radio" name="stars" value="4">
            <span class="icon">★</span>
            <span class="icon">★</span>
            <span class="icon">★</span>
            <span class="icon">★</span>
        </label>
        <label>
            <input type="radio" name="stars" value="5">
            <span class="icon">★</span>
            <span class="icon">★</span>
            <span class="icon">★</span>
            <span class="icon">★</span>
            <span class="icon">★</span>
        </label>
    </form>
</div>
<div class="rating_content">
  <div class="common_input_warp">
    <label for="">Your message </label>
<div class="form-group">
  <textarea class="form-control" name="" id="" cols="30" rows="10" placeholder="Write Something"></textarea>
</div>
  </div>
</div>
        </div>
        </div>
        <div class="modal-footer reffer_btn ratingsumitbtn">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">SKIP	</button>
          <button type="button" class="btn btn-secondary skip_btn" data-dismiss="modal">SUBMIT</button>
        </div>
      </div>
    </div>
  </div>
