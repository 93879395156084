import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  {
    path: 'page',
    loadChildren: () => import('./modules/page/page.module').then(m => m.PageModule), canActivate: [AuthGuard]
  },
  {
    path: 'static',
    loadChildren: () => import('./static/static.module').then(m => m.StaticModule)
  },
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule), canLoad: [LoginGuard]
  },
  // { path: '', redirectTo: 'landing', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
