<div class="container">
  <div class="row" style="margin: 10px 10px">
    <div class="col-md-12">
      <div class="custTest">
        <h1>Customer Testimonial</h1>
      </div>
      <div>
        <owl-carousel-o [options]="customOptions" class="testimonial-carousel">
          <ng-container *ngFor="let slide of slidesStore">
            <ng-template carouselSlide [id]="slide.id">
              <img [lazyLoad]="slide.src" [defaultImage]="slide.thumbnail"
              [title]="slide.title" style="border: 5px solid #0a94d6;"
              (error)="onImgError($event)">
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
    <div class="col-md-6" *ngFor="let item of videoStore">
      <mat-card class="example-card">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{item.name}}</mat-card-title>
          <mat-card-subtitle>{{item.address}}</mat-card-subtitle>
        </mat-card-header>
        <!-- <img
          mat-card-image
          src="https://material.angular.io/assets/img/examples/shiba2.jpg"
          alt="Photo of a Shiba Inu"
        /> -->
        <div class="embed-responsive embed-responsive-4by3" style="padding-bottom: 60%;">
          <!-- <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/CTxIA2nxR74?rel=0"></iframe> -->
          <div class="embed-responsive-item respVideo">
            <video controls
              width="250"
              height="200"
              muted
              preload="metadata"
              >
              <source src="{{item.url}}"
                      type="video/mp4">
              <!-- <source src="/media/cc0-videos/flower.mp4"
                      type="video/mp4"> -->
              This browser does not support the HTML5 video element.
          </video>
          </div>
        </div>
        <mat-card-content style="margin-top: 5px;">
          <p>
            Gyan Dairy provides you only the purest milk and milk products so that your family can enjoy healthy and fresh wide-range of dairy products.
          </p>
        </mat-card-content>
        <!-- <mat-card-actions>
          <button mat-button>LIKE</button>
          <button mat-button>SHARE</button>
        </mat-card-actions> -->
      </mat-card>
    </div>
  </div>
</div>
