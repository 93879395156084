<div class="termsWrapper">
  <h4>Privacy Policy</h4>
  <div class="termscontent-wrap">
    <p>The terms “We” / “Us” / “Our”/ “Company” / “Gyan Fresh” individually and collectively refer to C.P. Milk and Food Products Private Limited and the terms “User”/ “You” / “Your” individually and collectively refer to the users of the application.
    </p>
    <p>The Company has adopted this Privacy Policy to set out the manner in which personal data and other information is collected, received, stored, processed, disclosed, transferred, dealt with and handled by the Company in relation to Your use of our services through the Gyan Fresh mobile application (<strong>“Platform”</strong>) or initiating or undertaking any transaction on the Platform. This Privacy Policy does not apply to information that You provide to, or that is collected by, any third-party through the Platform, and any third-party site that You access or use in connection with the services offered on the Platform.
    </p>
    <p>This Privacy Policy is incorporated into and subject to Our Terms of Use and shall be read harmoniously and in conjunction with the Terms. In the event of any conflict between the Terms and this Privacy Policy, the provisions of the Terms shall supersede the Privacy Policy. 
    </p>
    <div class="contentswrap">
      <ol>
        <li>
          <h5>Personal Information</h5>
          <ol>
            <li>C.P. Milk and Food Products Private Limited is the licensed owner of the brand / trademark Gyan Fresh and the Platform. We respect Your privacy. This Privacy Policy provides concisely the manner in which Your data is collected and used by Us on the Platform. As a user of the Platform, You are advised to please read the Privacy Policy carefully before using or registering on the Platform or accessing material, information or availing any services through the Platform. By accessing the services provided by the Platform, You agree to the collection and use of Your data by us in the manner provided in this Privacy Policy and agree to be legally bound by the same.
            </li>
            <li>You may choose not to provide Us with any personal information or information as required to complete your task / transactions initiated on Your Platform. If We do not receive information required for Your tasks, We may choose not to complete Your task / transaction initiated on the Platform.  
            </li>
          </ol>
        </li>
        <li>
          <h5>Information Collected
          </h5>
          <ol>
            <li>We may ask You to provide Us with certain personally identifiable Information. We may collect this information through various means and in various places through the Platform, including account registration forms, contact us forms, or when you otherwise interact with us or use the Platform. You understand and acknowledge that by choosing the Platform (whether browsing it or making an account on it) You have allowed the Company to use your personal information. 
            </li>
            <li>
              When You sign up to use the Platform, You create a user profile. We shall ask You to provide only such information which is for a lawful purpose and connected with rendering our services on the Platform and necessary to be collected by Us for such purpose. The current data fields that You might be requested to submit are, amongst others, Your name, address, email id, mobile number, demographic information (age / gender / occupation / education), credit card / debit card details and any other information related to the above and as requested by Us from time to time.
            </li>
            <li>Our services are primarily provided through the Platform of the Mobile Application. We may collect and use technical data and related information, including but not limited to, technical information about Your device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other services to You (if any) related to such Platform. </li>
            <li>When You use our Platform, it may automatically collect and store some or all of the following information from Your mobile device: 
              <ul>
                <li>Your preferred language and country site (if applicable);
                </li>
                <li>The manufacturer and model of your mobile device;
                </li>
                <li>Your mobile operating system;
                </li>
                <li>The type of mobile internet browsers you are using;
                </li>
                <li>Your geolocation;
                </li>
                <li>Information about how you interact with the mobile application, such as how many times you use a specific part of the Mobile Application over a given time period, the amount of time you spend using the Mobile Application, how often you use the Mobile Application, actions you take in the Mobile Application and how you engage with the Mobile Application;</li>
                <li>Information to allow us to personalize the services and content available through the Mobile Application;
                </li>
                <li>Usage details, websites visited, IP addresses and information collected through cookies, web beacons and other tracking technologies.
                </li>
              </ul>
            </li>
            <li>We collect transaction details related to Your use of Our Platform, including the type of service requested, data and time the service was provided, amount charged, and other related transaction details. If you make any purchases on the Platform, we may collect and store information about You to process Your request and automatically complete forms for future transactions, including but not limited to your phone number, address, email billing information and credit or payment card information. This information may be shared with 3rd parties which assist in processing and fulfilling your request including Payment Card Industry (PCI) compliant payment gateway processors. When you submit credit or payment card information, we encrypt the information using industry standard technologies. </li>
            <li>We may receive information about You from third parties, such as other users, partners or our affiliated companies or if You use any of the other websites/apps We operate or the other service We provide.  
            </li>
            <li>On receiving personal information about our Users, You no longer remain anonymous to Us. We may use this information to do internal research on Your demographics, interests, and behaviour to better understand, protect and serve You better. This information is compiled and analyzed on an aggregated basis and in a manner that does not personally identify You. We indicate fields that are mandatorily required to be filled and fields that are optional. You may decide whether or not to provide such information to Us. 
            </li>
          </ol>
        </li>
        <li><h5>Manner of collection of information 
        </h5>
        <ol>
          <li>We collect personally identifiable information about You from the information You affirmatively and voluntarily give to Us or the information automatically collected when You visit Our Platform or use any of Our services as mentioned above or any combination thereof. </li>
          <li>The Platform may contain links to other websites. We are not responsible for the privacy practices of such websites which we do not own, manage or control. The Platform and third party websites and vendors with whom We partner or associate with, including Google, use first-party cookies (such as the Google Analytics cookie), third-party cookies (such as the Double-click cookie) pixel tags, web beacons, or technologies to store, inform, optimize, and serve ads based on your past visits to the Platform / third party websites.</li>
          <li>When You send emails or other communications to Us, We may retain those communications in order to process Your inquiries, respond to Your requests and improve Our services.
          </li>
        </ol>
      </li>
      <li>
        <h5>Manner of use of the information</h5>
        <ol>
          <li>The information collected by Us through our Platform maybe used, inter alia, for the following purposes: 
            <ol>
              <li>To allow You to use the services on the Platform;
              </li>
              <li>To allow You to undertake or initiate any transaction on the Platform;
              </li>
              <li>For internal record keeping of the Company;
              </li>
              <li>To improve Your usage of the Platform, to manage Your Account, etc.; 
              </li>
              <li>To process payments with respect to transactions initiated on the Platform;
              </li>
              <li>To respond to Your comments, reviews and questions and provide better services;
              </li>
              <li>To communicate important notices or changes to the terms of use provided by Us on the Platform, use of the Platform and the terms / policies which govern the relationship between You and the Company
              </li>
              <li>Internal purposes of the Company such as auditing, data analysis and research conducted either indirectly / directly by Us
              </li>
              <li>For any other purpose with Your consent and as updated in this Privacy Policy. </li>
            </ol>
          </li>
          <li>We will use Your personal information to provide personalized features to You on the application and to provide for promotional offers to You through the application and other channels. We will also provide this information to our business associates and partners to get in touch with You when necessary to provide the services requested by You. We will use this information to preserve transaction history as governed by existing law or policy. We may also use contact information internally to direct our efforts for product improvement, to contact You as a survey respondent, to notify You if You win any contest; and to send you promotional materials from our contest sponsors or advertisers. </li>
          <li>We will also use this information to serve various promotional and advertising materials to You via display advertisements through the Google Ad network on third party websites. You can opt out of Google Analytics for Display Advertising and customize Google Display network ads using the Ads Preferences Manager. Information about Users on an aggregate (excluding any information that may identify you specifically) covering User transaction data and User demographic and location data may be provided to Our partners for the purpose of creating additional features on the application, creating appropriate merchandising or creating new products and services and conducting marketing research and statistical analysis of customer behavior and transactions.
          </li>
          <li>We may use personal information to resolve disputes that may arise with the use of Our services on the Platform, help promote a safe service to You on the Platform, measure consumer interest in Our services, customize Your experience, detect and protect Us against error, fraud and other illegal activity, enforce Our terms and conditions. 
          </li>
          <li>We shall be entitled to retain Your Personal Information for such duration as may be required for the purposes specified hereunder and shall be used by Us only in consonance with this Privacy Policy. 
          </li>
        </ol>
      </li>
      <li>
        <h5>With whom information is shared
        </h5>
        <ol>
          <li>We share Your information with third parties and third-party service providers upon receipt of request by You to initiate a transaction to carry out specific service requests and fulfill such service requests. 
          </li>
          <li>We will not use Your financial information for any purpose other than to complete a transaction with You. We do not rent, sell or share Your personal information and will not disclose any of Your personally identifiable information to third parties. In cases where We have Your permission to provide products or services You've requested and such information is necessary to provide these products or services the information may be shared with Our business associates and partners. We may, however, share user information on an aggregate with our partners or third parties where We deem necessary.</li>
          <li>We may use your information for promotional offers, to help investigate, prevent or take action regarding unlawful and illegal activities, suspected fraud, potential threat to the safety or security of any person, violations of the application’s terms and conditions of use or to defend against legal claims; special circumstances such as compliance with summons, court orders, requests/order from legal authorities or law enforcement agencies requiring such disclosure.
          </li>
          <li>We may need to disclose Your information, to protect and defend the rights or property of the Company, including to enforce Our agreements, policies, and Terms; to protect the personal safety of the Company, its Users, members, employees or any other person, in an emergency and to protect the Company from incurring any legal liability. In such an event, the Company is under no legal obligation to specifically inform You or seek Your approval or consent. </li>
          <li>We may share sensitive personal information of Our Users to a third party without obtaining the prior consent of the User in the following limited circumstances:
            <ol>
              <li>When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms or for complying with the applicable laws and regulations.
              </li>
              <li>We propose to share such information within our group companies and officers and employees of our group companies for the purpose of processing personal information on our behalf. We also ensure that these recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures. 
              </li>
            </ol>
          </li>
          <li>Any content or personal information that You share or upload on any current or future publicly viewable portion of the Platform (on discussion boards, in messages and chat areas, etc.) will be publicly available, and can be viewed by others. </li>
          <li>If You provide a mobile phone number and/or e-mail address, the Company, or other parties registered on the Platform may call You or send You communications in relation to Your use of the Platform or any transaction initiated by You on the Platform. We and our affiliates will share/transfer some or all of the collected information (personal or otherwise) with another business entity should We (or our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business, in connection with or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution, transaction or proceeding. Should such a transaction occur, that other business entity (or the new combined entity) will be contractually bound to comply with the terms of this Privacy Policy.
          </li>
          <li>We do not disclose personal information about identifiable individuals to advertisers, but We may provide them with aggregate and/or anonymized information about You and other registered Users, to help advertisers reach the kind of audience they want to target. We may make use of the information We have collected from You to enable Us to comply with our advertisers' wishes by displaying their advertisement to that target audience.
          </li>
        </ol>
      </li>
      <li>
        <h5>Choices available with you regarding collection, use and distribution of information 
        </h5>
        <ol>
          <li>To protect against the loss, misuse and alteration of User information and to safeguard and help prevent unauthorized access to Your information and to maintain data security, we have in place appropriate physical, electronic and managerial procedures. For example, our servers are accessible only to authorized personnel and Your information is shared with employees and authorized personnel on a need to know basis to complete the transaction and to provide the services requested by You. Although We will endeavor to safeguard the confidentiality of Your personally identifiable information, transmissions made on the internet cannot be made absolutely secure. 
          </li>
          <li>We assume no liability or responsibility for disclosure of your information due to errors in transmission, unauthorized third-party access, or other causes beyond our control. You play an important role in keeping your personal information secure. You should not share your username, password, or other security information pertaining to your Account on the Platform with anyone. If we receive instructions using your user name and password, we will consider that you have authorized the instructions and comply with the same. 
          </li>
          <li>You acknowledge that all information disclosed by You shall be deemed to be disclosed willingly and without any coercion. No liability pertaining to the authenticity / genuineness / misrepresentation / fraud / negligence, etc. of the information disclosed shall lie on the Company nor will the Company in any way be responsible to verify any information obtained from You. </li>
          <li>You may choose to withdraw Your consent provided hereunder at any point in time. In case You do not provide or later withdraw Your consent, We reserve the option to not allow You to undertake any transaction on the Platform or access the Service available on the Platform for which the said information was sought on the Platform. However, if You are a part of any on-going transaction on the Platform, We reserve the right to retain all your information until completion of the transaction. Further, You acknowledge and agree that in case of such withdrawal of Your consent, the Company reserves the right to store Your information in an anonymized form such that the information stored will not be attributable to you or identify You in any manner whatsoever. The Company shall complete the anonymizing the data within 30 days from the date of withdrawal of consent.
          </li>
        </ol>
      </li>
      <li>
        <h5>Correction of inaccuracies in information 
        </h5>
        <ol>
          <li>
            You represent and warrant that any and all information, including but not limited to Your personal information is absolutely correct and complete in all aspects. To correct or update any information provided by You, the Platform allows You to edit the same. In the event of loss of access details, You can send an email to our customer service team at care@gyandairy.com. Once You correct / update the information on the Platform or send us an email with respect to the same, You agree to comply with the instructions as may be provided / communicated to You by Us. 
          </li>
        </ol>
      </li>
      <li>
        <h5>Public Posts 
        </h5>
        <ol>
          <li>If the Platform permits You, You may provide Your feedback, reviews, comments, etc. on the Platform on Your use of the Platform and/or in relation to any transactions on the Platform on any portion of the Platform that is publicly viewable (“Posts”). The Company shall have an unconditional right to remove and delete any Post or such part of the Post that, in the sole opinion of the Company, does not comply with the conditions in the applicable Terms of Use or applicable law. All Posts shall be publicly accessible and visible to all Users of the Platform. Company reserves the right to use, reproduce and share such Posts for any purpose at its discretion. If You delete Your Posts from the Platform, copies of such Posts may remain viewable in archived pages, or such Posts may have been copied or stored by other Users. 
          </li>
        </ol>
      </li>
      <li>
        <h5>Third Party Link
        </h5>
        <ol>
          <li>You hereby acknowledge and agree that when You use the Platform, there may be certain links which may direct You to other websites or applications not operated/maintained by the Company or third-party advertising companies to serve advertisements when You visit our Platform (“Other Sites”). These companies or third-party platform may use Your information (not including Your name, address, email address, or telephone number) about Your visits to this and other Platform in order to provide advertisements about services of interest to You. This Privacy Policy does not apply to information that You provide to, or that is collected by, any Other Site through the Platform, and any Other Site that You access or use in connection with the use of the Platform. The manner in which Your information is collected, received, stored, processed, disclosed, transferred, dealt with and handled by such Other Site(s) is governed by the terms and conditions and privacy policy of the respective Other Site(s). The Company urges You to acquaint yourself with the terms and conditions and privacy policy of every such Other Site(s).
          </li>
          <li>We may collect, process and store your user id associated with any social media account such as your Facebook and Google account that you use to sign into the Platform or connect with or use with the Platform. When you sign into your account with your social media account information, or otherwise connect to your social media account with the Platform, you consent to Our collection, storage and use in accordance with this privacy policy of the information that you make available to us through the social media interface. This could include, without limitation, any information that you have made public through your social media account, information that the social media service shares with us, or information that is disclosed during the sign-in process. Please see your social media provider’s privacy policy for more information about how they share information when you choose to connect your social media account on the Platform.  </li>
          <li>The Company hereby expressly disclaims all liabilities with respect to the manner in which the Other Site(s) collects and/or uses Your information. On the Platform, there may be third parties who advertise their products and/or services. Such third parties may place or recognize a unique "cookie" on Your browser and may use information about Your visits to Our Platform and other websites in order to provide advertisements about goods and services of interest to You. We shall, in no event, be liable for any unauthorized or unlawful disclosures of Your personal information or any other information made by advertisers, who are not subject to Our control.
          </li>
        </ol>
      </li>
      <li>
        <h5>Policy updates
        </h5>
        <ol>
          <li>
            We reserve the right to change or update this policy at any time. Such changes shall be effective immediately upon updating. You should review this Privacy Policy regularly for changes. You can determine if changes have been made by checking the “Last Updated” legend. If we make any significant changes, We will endeavor to provide You with reasonable notice of such changes, such as via prominent notice on the Platform or to Your email address on record and where required by applicable law, We will obtain Your consent. Your continued use of the Platform after we publish or send a notice about our changes to this Policy shall signify Your consent to such changes and agreement to be legally bound by the same. 
          </li>
        </ol>
      </li>
      <li>
        <h5>
          Complaints 
        </h5>
        <ol>
          <li>
            In case of any dissatisfaction in relation to the Platform, You shall first file a formal complaint with the customer service of the Company, prior to pursuing any other recourse. The complaints can be lodged at care@gyandairy.com, and upon lodging a complaint You agree to provide complete support to the customer service team and provide them with such reasonable information as may be sought by them from You. The decision of the Company on the complaints shall be final and You agree to be bound by the same. 
          </li>
        </ol>
      </li>
    </ol>
  </div>
</div>
</div>