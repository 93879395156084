import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-cooking',
  templateUrl: './cooking.component.html',
  styleUrls: ['./cooking.component.scss']
})
export class CookingComponent implements OnInit, AfterViewInit, OnDestroy {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  };
  slidesStore = [{
    src: 'assets/img/mobscreen-2.png',
    alt: 'assets/img/placeholder.png',
    title: 'testing the testimonial',
    id: '1'
  }, {
    src: 'assets/img/invite.png',
    alt: 'assets/img/placeholder.png',
    title: 'testttt',
    id: '2'
  }];
  interval;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // if (this.route.snapshot.queryParams.od) {
    //   this.interval = setInterval(() => {
    //     let odEl = document.getElementById('onedirect-messenger-main');
    //     if (odEl && odEl.style) {
    //       odEl.style.display = 'none';
    //       // clearInterval(this.interval);
    //     }
    //   }, 500);
    // }
  }

  ngOnDestroy() {
    // clearInterval(this.interval);
  }

}
