<div class="container">
  <div class="row" style="margin: 10px 10px">
    <div class="col-md-12">
      <div class="">
        <h1>Gyan Cooking</h1>
      </div>
      <div class="testimonial-carousel">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of slidesStore">
            <ng-template carouselSlide [id]="slide.id">
              <img [src]="slide.src" [alt]="slide.alt" [title]="slide.title" />
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
    <div class="col-md-6">
      <mat-card class="example-card">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>Gyan Fresh</mat-card-title>
          <mat-card-subtitle>Hazratganj, Lucknow</mat-card-subtitle>
        </mat-card-header>
        <!-- <img
          mat-card-image
          src="https://material.angular.io/assets/img/examples/shiba2.jpg"
          alt="Photo of a Shiba Inu"
        /> -->
        <div class="embed-responsive embed-responsive-4by3">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/salK5gxuoFQ"></iframe>
        </div>
        <mat-card-content style="margin-top: 5px;">
          <p>
            Gyan Dairy provides you only the purest milk and milk products so that your family can enjoy healthy and fresh wide-range of dairy products.
          </p>
        </mat-card-content>
        <!-- <mat-card-actions>
          <button mat-button>LIKE</button>
          <button mat-button>SHARE</button>
        </mat-card-actions> -->
      </mat-card>
    </div>
    <div class="col-md-6">
      <mat-card class="example-card">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>Gyan Fresh</mat-card-title>
          <mat-card-subtitle>Hazratganj, Lucknow</mat-card-subtitle>
        </mat-card-header>
        <!-- <img
          mat-card-image
          src="https://material.angular.io/assets/img/examples/shiba2.jpg"
          alt="Photo of a Shiba Inu"
        /> -->
        <div class="embed-responsive embed-responsive-4by3">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/salK5gxuoFQ"></iframe>
        </div>
        <mat-card-content style="margin-top: 5px">
          <p>
            Gyan Dairy provides you only the purest milk and milk products so that your family can enjoy healthy and fresh wide-range of dairy products.
          </p>
        </mat-card-content>
        <!-- <mat-card-actions>
          <button mat-button>LIKE</button>
          <button mat-button>SHARE</button>
        </mat-card-actions> -->
      </mat-card>
    </div>
  </div>
</div>
