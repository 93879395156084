import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermComponent } from './term/term.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AboutComponent } from './about/about.component';
import { RefundComponent } from './refund/refund.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { TermGyanStarComponent } from './term-gyan-star/term-gyan-star.component';
import { HowItWorkDeskComponent } from './how-it-work-desk/how-it-work-desk.component';
import { PurityMeterComponent } from './purity-meter/purity-meter.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { CookingComponent } from './cooking/cooking.component';
import { SpinWheelTermsComponent } from './spin-wheel-terms/spin-wheel-terms.component';
import { DailyStreakTermsComponent } from './daily-streak-terms/daily-streak-terms.component';
import { SpinWheelComponent } from './spin-wheel/spin-wheel.component';


const routes: Routes = [
  { path: 'terms-and-condition', component: TermComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  { path: 'about', component: AboutComponent },
  { path: 'refund', component: RefundComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'terms-gyan-star', component: TermGyanStarComponent },
  { path: 'how-it-work-desk', component: HowItWorkDeskComponent },
  { path: 'purity-meter', component: PurityMeterComponent },
  { path: 'testimonial', component: TestimonialComponent },
  { path: 'cooking', component: CookingComponent },
  { path: 'spin-wheel-terms', component: SpinWheelTermsComponent },
  { path: 'daily-order-streak-terms', component: DailyStreakTermsComponent },
  { path: 'spin-wheel', component: SpinWheelComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticRoutingModule { }
