<div class="termsWrapper">
  <h4>Term and Condition – Gyan Star Loyalty Program</h4>
  <div class="termscontent-wrap">
    <div class="contentswrap">
      <ol style="list-style-type: none;padding: 0;">
        <li><h5>The program membership benefits and details –</h5>
          <ul>
            <li>
              The members will be able to earn 1 star for every Rs. 10 purchase that they make (applicable on purchase of single product or on a combination of the products)
            </li>
            <li>
              The valuation of one star earned is equal 0.1 Rs when converted into Rupees for redeeming the offer.
            </li>
            <li>
              There is no expiry date associated with the stars; can be redeemed as and when pleased.
            </li>
            <li>
              The members can redeem from wide variety of Gyan products listed under the Star category where the estimation of the prices of the products are provided in terms of stars.
            </li>
            <li>
              The members have an option to view the number of stars earned in each transaction to keep track of the status of their redeemable stars.
            </li>
          </ul>
        </li>
        <li>
          <h5>The stars are earned in the following manner -</h5>
          <ul>
            <li>
              On a purchase of an item for Rs 10, members will earn 1.0 star whose valuation is Rs. 0.10. Similarly, when the purchase amount reaches Rs. 100 the balance will show earnings of 10 star, whose valuation is Rs 1.
            </li>
          </ul>
        </li>
        <li>
          <h5>
            The stars are used in the following manner -
          </h5>
          <ul>
            <li>
              A member having 500 stars in his balance can redeem the stars for specific products with value of up to Rs. 50 under the Gyan star category on which the redemption of stars is applicable.<br>
              <span style="font-style: italic;">* In case the stars are in decimal (500.4 stars will round off to 501 stars; 500.5 stars and onwards will also be considered as 501 stars.)</span>
            </li>
            <li>
              This program is brought to you by Gyan fresh website, Gyan fresh app (Ios and Android) and or its affiliates. If you are up for the Gyan fresh loyalty program you are obliged to adhere by these terms and conditions as mentioned below.
            </li>
            <li>
              The participants of the program (“you" and "your") are required to have a valid registered address.
            </li>
            <li>
              It is also applicable in case of participation from retail outlets, associations and corporations.
            </li>
            <li>
              The availing of the stars is applicable on all Gyan Fresh products subjected to the availability of stock or an ongoing major sales event.
            </li>
            <li>
              The loyalty stars collected by the members would be subjected to variability in market prices and ongoing conditions.
            </li>
            <li>
              We may terminate your access to the benefits of Gyan Star Loyalty Program, if you violate these terms and conditions or any other applicable law or are involved in any fraud or misuse of the program.
            </li>
            <li>
              Events beyond the control of Gyan Fresh such as equipment failure, electronic data transfer failures, civil disturbance, current pandemic situation and its obligations, which can lead to inability to deliver will enable us to terminate the membership.
            </li>
          </ul>
        </li>

</ol>
</div>
</div>
</div>
