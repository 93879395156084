<div class="termsWrapper">
  <h4>Milestone Rewards</h4>
  <div class="termscontent-wrap">
    <h3 style="text-align: left; margin-bottom: 35px">
      Milestone rewards for our best customer
    </h3>
    <h4 style="text-align: left">How it works ?</h4>
    <p>
      Order daily to win more and more. Get incremental rewards for maintaining
      ordering streak.
    </p>
    <h4 style="text-align: left;">Offer Details:</h4>
    <p>1. Customers are rewarded for ordering regularly.</p>
    <p>2. Incremental rewards are given to customers basis their purchase.</p>
    <p>3. Missing a day resets the streak to day 0.</p>
    <p>4. Rewards are redeemed at Gyan star store.</p>
    <p>5. Gyan Fresh reserves the right to change the offers at their discretion.</p>
  </div>
</div>
