// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { app_strings } from "src/app/shared/_constant/app_strings";

export const apiVersion = 'v12';
export const environment = {
  production: false,
  paytm: {
    // mid: app_strings.PAYTM_STAGING.MID,
    // websiteName: app_strings.PAYTM_STAGING.website,
    // script: 'paytmStaging'        
    // live paytm
    mid: app_strings.PAYTM_PRODUCTION.MID,
    websiteName: app_strings.PAYTM_PRODUCTION.website,
    script: 'paytmProd'

  },
   api_url: `https://api.gyandairy.com/api/user/${apiVersion}/`, // live
  //  api_url: `http://3.109.142.12:3000/api/user/${apiVersion}/`, // preprod
//    api_url: `https://preprod.gyanfresh.com/api/user/${apiVersion}/`, // preprod
   //api_url: `http://192.0.0.174:6464/api/user/${apiVersion}/`, // local
  // paytm: {
  //   mid: app_strings.PAYTM_PRODUCTION.MID,
  //   websiteName: app_strings.PAYTM_PRODUCTION.website,
  //   script: 'paytmProd'
  // },
  firebase: {
    apiKey: 'AIzaSyB1y_lvJCzdb4XMK4jqD-QG4hKrFzapqaI',
    authDomain: 'gyan-fresh-278410.firebaseapp.com',
    databaseURL: 'https://gyan-fresh-278410.firebaseio.com',
    projectId: 'gyan-fresh-278410',
    storageBucket: 'gyan-fresh-278410.appspot.com',
    messagingSenderId: '90505039313',
    appId: '1:90505039313:web:abe156b515fec9d011e4f0',
  },
  razor_url: 'https://api.razorpay.com/v1/orders'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
