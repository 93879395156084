import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { StaticService } from '../static.service';

declare var $: any;

@Component({
  selector: 'app-purity-meter',
  templateUrl: './purity-meter.component.html',
  styleUrls: ['./purity-meter.component.scss']
})
export class PurityMeterComponent implements OnInit, OnDestroy, AfterViewInit {
  dataSubs$: Subscription;
  dataList = [];
  selectedData;
  interval;

  constructor(private staticService: StaticService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getPurityMeterData();

  }

  ngOnDestroy() {
    try {
      // clearInterval(this.interval);
      this.dataSubs$.unsubscribe();
    } catch (error) {
      console.log(error);
    }
  }

  counterAnimate() {
    $('.count_value').each(function () {
      $(this).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 2000,
        easing: 'swing',
        step: function (now) {
          $(this).text(Math.ceil(now));
        }
      });
    });
  }

  getPurityMeterData() {
    this.dataSubs$ = this.staticService.getPurityMeter('getPurityMeterNew').subscribe(res => {
      if (res && res.status === 200) {
        this.dataList = res.data;
        this.selectedData = this.dataList[0];
        setTimeout(() => {
          this.counterAnimate();
        }, 500);
      }
    });
  }

  ngAfterViewInit() {
    // if (this.route.snapshot.queryParams.od) {
    //   this.interval = setInterval(() => {
    //     let odEl = document.getElementById('onedirect-messenger-main');
    //     if (odEl && odEl.style) {
    //       odEl.style.display = 'none';
    //       // clearInterval(this.interval);
    //     }
    //   }, 500);
    // }
  }

}
