import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { ApiService } from './serviceFile/api.service';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptProviders } from './http-interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CommonService } from './serviceFile/common.service';
import { MaterialModule } from './material.module';
import { GlobalDialogComponent } from './shared/global-dialog/global-dialog.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from 'src/environments/environment';
import { MessagingService } from './serviceFile/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxStripeModule } from 'ngx-stripe';
import { NumberOnlyDirective } from './shared/directives/number-only.directive';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { FirebaseAuthService } from './serviceFile/firebaseAuth.service';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import {MatRadioModule} from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { StaticModule } from './static/static.module';
import {MatCardModule} from '@angular/material/card';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { ServiceWorkerModule } from '@angular/service-worker';
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
@NgModule({
  declarations: [
    AppComponent, GlobalDialogComponent, NumberOnlyDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }), // ToastrModule added
    MaterialModule,
    MatNativeDateModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    NgxSpinnerModule,
    NgxStripeModule.forRoot('pk_test_51GuYfsKaRmdS0PgZHQRlmNLeET3OMfzFmaJ3UddxBTkcKXtOS8vFaULl2WOQmd0CaAhLwvWgPW4NCRUwK2tqcwTK00hngZqDvU'),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDAIJ08X0BznyCJXQiD5Dt3cRXWoj8WZd8'
    }),
    AngularFireDatabaseModule,
    // ServiceWorkerModule.register('ngsw-worker.js'),
    GooglePlaceModule,
    MatRadioModule,
    FormsModule,
    StaticModule,
    MatCardModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [AuthGuard, LoginGuard, ApiService, httpInterceptProviders, CommonService, { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }, MessagingService, FirebaseAuthService],
  bootstrap: [AppComponent],
  entryComponents: [GlobalDialogComponent]
})
export class AppModule { }
